<template>
  <div v-frag >
    <Loader v-if="loadingSlots"/>
    <div class="container-fluid" v-if="!loadingSlots">
      <div class="row" style="padding-bottom:60px">
        <div class="col-md-2 hidden-sm hidden-xs"></div>
        <div class="col-md-8">
          <p class="visible-xs"></p>
          <the-carousel :slides="casinoSlides"/>
          <div class="visible-xs search_">
            <input type="text" v-model="search"  class="form-control" :placeholder="`${$t('betslip.search')}`" @keyup="selectByName">
          </div>
          <div class="cat">
            <ul class="list-inline">
              <li><span :class="{ 'select' : casinoType == 'popular' }" @click="changeCasinoType('popular')">{{ $t('casino.popular') }}</span></li>
              <li><span :class="{ 'select' : casinoType == 'newgames' }" @click="changeCasinoType('newgames')">{{ $t('casino.new-games') }}</span></li>
              <li><span :class="{ 'select' : casinoType == 'dropnwins' }" @click="changeCasinoType('dropnwins')">{{ $t('casino.drop-n-wins') }}</span></li>
              <li><span :class="{ 'select' : casinoType == 'slots' }" @click="changeCasinoType('slots')">{{ $t('casino.slots') }}</span></li>
              <li><span :class="{ 'select' : casinoType == 'table' }" @click="changeCasinoType('table')">{{ $t('casino.table-games') }}</span></li>
              <li><span :class="{ 'select' : casinoType == 'live' }" @click="selectCat(true)">{{ $t('casino.live-games') }}</span></li>
            </ul>
            <div class="hidden-xs">
              <input type="text" v-model="search"  class="form-control" :placeholder="`${$t('betslip.search')}`" @keyup="selectByName">
            </div>
          </div>
          <template v-if="!live">
            <template v-if="!loadingSlots">
              <div class="casino-body">
                <div v-frag v-for="game in casinoGames" :key="game.gameId">
                  <card :game="game" @playGame="playGame" @playDemo="startDemo"  />
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <lobbyCasinoVue :ids="[]"/>
          </template>
        </div>
        <div class="col-md-2 hidden-sm hidden-xs"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "vue-loading-overlay/dist/vue-loading.css";
import { CASINO } from "@/config/casino_confs";
import card from "./casino/casino.show.vue";
import lobbyCasinoVue from "./lobby.casino.vue";
import Loader from "@/components/sections/loader.vue";

export default {
  name: "home_casino",
  components:{
    card,
    lobbyCasinoVue,
    Loader
  },
  data() {
    return {
      computedGames: [],
      gameUrl: "",
      fullPage: true,
      paginate:["games"],
      newgames:[
        "vs25wolfjpt",
        "vs40hotburnx",
        "vs20trswild2",
        "vs20wolfie",
        "vs10mmm",
        "vswaysjkrdrop",
        "vs25kingdoms",
        "vs20sh",
        "vs40sh",
        "vs100sh",
        "vs40cosmiccash",
        "vs25bomb",
        "vs20sugarrush",
        "vs10txbigbass",
        "vs25copsrobbers",
        "vs10egrich",
        "vs20amuleteg",
        "vswayszombcarn",
        "vswayswildwest",
        "vs20cleocatra",
        "vs5littlegem",
        "vs20stickysymbol",
        "vs20gobnudge",
        "vs10firestrike2",
        "vs50northgard",
        "vs10spiritadv",
        "vs20mustanggld2",
        "vs40cleoeye",
        "vs10chkchase",
        "vs20drtgold",
        "vs20farmfest",
        "vs10tictac",
        "vs20rainbowg",
        "vs20bchprty",
        "vs243queenie",
        "vswaysxjuicy",
        "vs10snakeladd",
        "vs50mightra",
        "vswayselements",
        "vs20ultim5",
        "vs20colcashzone",
        "vs10runes",
        "vs20rockvegas",
        "vs25goldparty",
        "vs40wanderw",
        "vs4096magician",
        "vswayscryscav",
        "vs20smugcove",
        "vs10bxmasbnza"
      ],
      popular:  [
        "vs10bbbonanza",
        "vs20fruitsw",
        "vs12bbb",
        "vs25copsrobbers",
        "vswaysrhino",
        "vs20olympgate",
        "vswaysbufking",
        "vswayslions",
        "vs25scarabqueen",
        "vswaysmadame",
        "vs10bxmasbnza",
        "vs25mustang",
        "vs432congocash",
        "vswaysbbb",
        "vswaysxjuicy",
        "vswaysdogs",
        "vs25chilli",
        "vs576treasures",
        "vswayshammthor",
        "vs25wolfgold",
        "vs20doghouse",
        "vs10bookoftut",
        "vs25goldparty",
        "vswayswildwest",
        "vs10bookfallen",
        "vs20sugarrush",
        "vs243queenie",
        "vs10snakeladd",
        "vs10floatdrg",
        "vs10firestrike2",
        "vs10spiritadv",
        "vs20mustanggld2",
        "vs20drtgold",
        "vs20rainbowg",
        "vs40wildwest",
        "vs20midas",
        "vs20gobnudge",
        "vs20amuleteg",
        "vs10egrich",
        "vs20farmfest",
        "vs20cleocatra",
        "vswayszombcarn",
        "vs40cleoeye",
        "vs10tictac"
      ],
      table: [
        "1301",
        "bjmb",
        "scwolfgold",
        "scgoldrush",
        "scdiamond",
        "scqog",
        "scsafari",
        "scpanda",
        "sc7piggies",
        "bjma",
        "rla",
        "bca"
      ],
      dropnwins: [
        "vs25wolfgold",
        "vs25mustang",
        "vs20fruitsw",
        "vs25scarabqueen",
        "vswaysrhino",
        "vs10bookoftut",
        "vs40wildwest",
        "vswaysdogs",
        "vs25chilli",
        "vs10bbbonanza",
        "vswaysmadame",
        "vs576treasures",
        "vswayswildwest",
        "vs20olympgate",
        "vswayshammthor",
        "vs10floatdrg",
        "vs20midas",
        "vswaysbufking",
        "vs12bbb",
        "vswaysbbb",
        "vs10bookfallen",
        "vswayslions",
        "vswaysbufking",
        "vswaysxjuicy",
        "vs40cleoeye",
        "vswayswildwest",
        "vs10txbigbass",
        "vs20mustanggld2",
        "vs25copsrobbers",
        "vs20sugarrush",
        "vs5sh",
        "vs20sh",
        "vs40sh",
        "vs100sh",
        "vswaysjkrdrop",
        "vs10mmm",
        "vs50juicyfr"
      ],
      casinoType: 'popular',
      live:false,
      search:'',
    };
  },
  mounted() {
    this.$store.dispatch("getCasinoGames", "slots");
  },
  computed: {
    ...mapGetters(["casinos", "loadingSlots",'casinoSlides']),
    casinoGames() {
      let temp;
      switch (this.casinoType) {
        case 'popular':
          temp = (this.casinos) ? this.casinos.filter( x => this.popular.includes(x.gameID)) : [];
          break;
        case 'newgames':
          temp = (this.casinos) ? this.casinos.filter( x => this.newgames.includes(x.gameID)) : [];
          break;
        case 'table':
          temp = (this.casinos) ? this.casinos.filter( x => this.table.includes(x.gameID)) : [];
          break;
        case 'dropnwins':
          temp = (this.casinos) ? this.casinos.filter( x => this.dropnwins.includes(x.gameID)) : [];
          break;
        case 'search':
        temp = (this.casinos) ? this.casinos.filter( x => x.gameName.toLowerCase().indexOf(this.search) > -1) : [];
        
          break;
        default:
          temp = this.casinos;
          break;
      }
      return temp
    }
  },
  methods: {
    onCancel() {
      // this.$store.dispatch('resetGameLoader')
    },
    getImageUrl(game) {
      return CASINO.getImageUrl(game.gameID);
    },
    playGame(item) {
      this.$router.push({
        name: "slot_renderer",
        params: { gameId: item.gameID },
      });
    },
    startDemo(item) {
      this.$router.push({name:"demo_renderer",params:{gameId:item.gameID}})
    },
    changeCasinoType(type) {
      this.search = '';
      this.live = false;
      this.casinoType = type;
    },
    selectCat(bool) {
      this.live = bool;
      this.casinoType = "live"
    },
    selectByName() {
      this.casinoType = 'search'
    }
  },
};
</script>

<style scoped>
 .form-control {
	background-color: var(--bg-dark-medium);
	color: var(--a-text);
	border: 1px solid var(--border-color);
}
  .casino {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .casino-card {
    border: 1px solid #494d59;
    border-radius: 10px;
    position: relative;
    /* background-color: #494d59 ; */
  }
  .casino-img {
    width: 100%;
    border-radius: 10px;
    max-height: 200px;
  }
  .casino-card-body {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 10px;
    opacity: 0;
    background-color: rgb(0, 0, 0);
    transition: all 0.3s ease-in-out 0s;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
  }
  .search_ {
    margin: 0px 0px 10px;
  }
  .casino-card:hover .casino-card-body {
    opacity: 0.95;
    z-index: 200;
  }
  .casino-card-body .play {
    display: flex;
    justify-content: space-around;
  }
  .play-game {
    background-color: #f7941e;
    border: 1px solid #f7941e;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    padding: 4px 10px;
    opacity: 1;
  }
  .play-demo {
    background-color: #073b6b;
    border: 1px solid #073b6b;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    padding: 4px 10px;
  }
  .casino-card-body .hed {
    font-size: 20px;
    display: block;
  }
  ul li {
    cursor: pointer;
    /* font-weight: bold; */
  }
  .cat {
    padding: 0px 0px 10px 0px;
  }
  .cat ul {
    white-space: nowrap;
    overflow-x: scroll;
    margin-bottom: 0px;
  }
  .cat span {
    display: inline-block;
    background-color: var(--bg-blue);
    padding: 4px 10px;
    border-radius: 15px;
    /* font-size: 12px; */
    cursor: pointer;
  }
  .cat span.select {
    background-color: var(--bg-orange);
  }

  @media screen and (min-width: 768px) {
    .cat {
      display: flex;
      justify-content: space-between;
    }
    .casino-card-body .hed {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    .casino-card-body .play {
      justify-content: space-between;
      flex-direction: column;
    }
    .casino-card-body {
      padding: 10px 5px;
    }
    .casino-card-body .hed {
      font-size: 16px;
    }
    .play-demo {
      margin-bottom: 5px;
    }
    .casino-img {
      min-height: 150px;
    }
    .featured-header.links {
      border-top: 0px;
    }
    .featured-header {
      background-color: transparent;
      padding: 5px ;
    }
    .featured-header ul {
      margin-bottom: 5px;
    }
      
}
</style>